<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Exam software</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Exam software
                </div>
              </div>

            </div>
          </div>

          <v-skeleton-loader
              v-if="loading"
              type="card-body"
          >
          </v-skeleton-loader>

          <v-skeleton-loader
              v-if="loading"
              type="search-wrapper"
          >
          </v-skeleton-loader>
          <div class="card-body">

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('title')" @click="sortBy('title')">Title</th>
                  <th class="px-3">Version Number</th>
                  <th class="px-3">Release Date</th>
                  <th class="px-3">OS Type</th>
                  <th class="px-3">Status</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="examSoftware">
                    <td class="px-3">
                      <a @click="editExamSoftware(examSoftware.id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ examSoftware.title }}
                      </a>
                    </td>
                    <td>
                      {{ examSoftware.version_number }}
                    </td>
                    <td>
                      {{ examSoftware.formatted_version_date ? examSoftware.formatted_version_date : '--' }}
                    </td>
                    <td class="px-3">
                      <ul>
                        <li v-if="examSoftware.mac_file_path">
                          Mac OS
                          <a :href="examSoftware.mac_file_path.thumb" target="_blank" class="ml-4 text-primary font-weight-bolder text-hover-primary mb-1">
                            <i class="fas fa-download"></i>
                          </a>
                        </li>
                        <li v-if="examSoftware.win_file_path">
                          Window OS
                          <a :href="examSoftware.win_file_path.thumb" target="_blank"  class="ml-4 text-primary font-weight-bolder text-hover-primary mb-1">
                            <i class="fas fa-download"></i>
                          </a>
                        </li>
                        <li v-if="examSoftware.msi_file_path">
                          MSI OS
                          <a :href="examSoftware.msi_file_path.thumb" target="_blank"  class="ml-4 text-primary font-weight-bolder text-hover-primary mb-1">
                            <i class="fas fa-download"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': examSoftware.is_active, 'badge-danger': !examSoftware.is_active }"
                      >{{ examSoftware.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>

                  </tr>
                  <tr v-if="examSoftware == null" >
                    <td colspan="6" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CreateAndUpdate from "@/view/pages/view/cms/exam-software/CreateAndUpdate";
import ExamSoftwareService from "@/services/cms/exam-software/ExamSoftwareService";

const examSoftware = new ExamSoftwareService();

export default {
  components: {CreateAndUpdate},
  data() {
    return {
      examSoftware: null,
      loading: false,
      sort: {
        key: '',
        isAsc: false
      },
      search: {
        title: '',
        version_number: '',
        version_date: '',
        is_active: ''
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ]
    }
  },
  computed: {
    sortedItems() {
      const list = this.examSoftware.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getAllExamSoftware() {
      this.loading = true;
      examSoftware
          .paginate(this.search, this.page)
          .then((response) => {
            if(response.data.data){
              this.examSoftware = response.data.data;
            }
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          }).finally(() => {
            this.loading = false;
      });
    },
    searchExamSoftware() {
      this.getAllExamSoftware();
    },

    editExamSoftware(softwareId) {
      this.$router.push({
        name:'exam-software-edit',
        params:{ softwareId: softwareId}
      });
    },
    resetSearch(){
      this.search = {};
      this.getAllExamSoftware();
    },
  },
  mounted(){
    this.getAllExamSoftware();
  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>